import {
	CONTACT_GET_LIST,
	CONTACT_GET_LIST_SUCCESS,
  CONTACT_GET_LIST_ERROR,

  CONTACT_COUNT,
  CONTACT_COUNT_SUCCESS,
  CONTACT_COUNT_ERROR,

  // CONTACT_GET_ONE,
	// CONTACT_GET_ONE_SUCCESS,
  // CONTACT_GET_ONE_ERROR,

  // CONTACT_ADD_ONE,
  // CONTACT_ADD_ONE_SUCCESS,
  // CONTACT_ADD_ONE_ERROR,

  // CONTACT_EDIT_ONE,
  // CONTACT_EDIT_ONE_SUCCESS,
  // CONTACT_EDIT_ONE_ERROR,

  CONTACT_DELETE_ONE,
  CONTACT_DELETE_ONE_SUCCESS,
  CONTACT_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

const INIT_STATE = {
	contacts: [],
  count: 0,
  contact: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CONTACT_GET_LIST:
      return { ...state, loading: true, error: ''};
    case CONTACT_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        contacts: action.payload.contacts || []
      };
		case CONTACT_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // COUNT
    case CONTACT_COUNT:
        return { ...state, loading: true, error: ''};
      case CONTACT_COUNT_SUCCESS:
        return { ...state, loading: false, count: action.payload.count || 0 };
      case CONTACT_COUNT_ERROR:
        return { ...state, loading: false };





      // DELETE ONE
    case CONTACT_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case CONTACT_DELETE_ONE_SUCCESS: 
    // console.log('CONTACT_DELETE_ONE_SUCCESS', action.payload);
     
      const contacts = state.contacts 
      if(action.payload.index!=null && action.payload.index!=undefined)
      contacts.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        contacts
      };
    case CONTACT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
