import { all } from 'redux-saga/effects';
// import authSagas from './auth/saga';
// import todoSagas from './todo/saga';
// import chatSagas from './chat/saga';
// import surveyListSagas from './surveyList/saga';
// import surveyDetailSagas from './surveyDetail/saga';
import CtgsSagas from './ctgs/saga';
import ProductsSagas from './products/saga';
import PacksSagas from './packs/saga';
import PromotionsSagas from './promotions/saga';
import UsersSagas from './users/saga';
import TaxesSagas from "./taxes/saga";
import SessionsSagas from "./sessions/saga";
import OrdersSagas from "./orders/saga";
import TrainingsSags from "./trainings/saga";
import ContactsSags from "./contacts/saga";

export default function* rootSaga(getState) {
  yield all([
    CtgsSagas(),
    ProductsSagas(),
    PacksSagas(),
    PromotionsSagas(),
    UsersSagas(),
    TaxesSagas(),
    SessionsSagas(),
    OrdersSagas(),
    TrainingsSags(),
    ContactsSags(),
    
    // authSagas(),
    // todoSagas(),
    // chatSagas(),
    // surveyListSagas(),
    // surveyDetailSagas()
  ]);
}
