import {
	PRODUCT_GET_LIST,
	PRODUCT_GET_LIST_SUCCESS,
  PRODUCT_GET_LIST_ERROR,

  PRODUCT_GET_ONE,
	PRODUCT_GET_ONE_SUCCESS,
  PRODUCT_GET_ONE_ERROR,

  PRODUCT_ADD_ONE,
  PRODUCT_ADD_ONE_SUCCESS,
  PRODUCT_ADD_ONE_ERROR,

  PRODUCT_EDIT_ONE,
  PRODUCT_EDIT_ONE_SUCCESS,
  PRODUCT_EDIT_ONE_ERROR,

  PRODUCT_DELETE_ONE,
  PRODUCT_DELETE_ONE_SUCCESS,
  PRODUCT_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

const INIT_STATE = {
	products: [],
  count: 0,
  product: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case PRODUCT_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        products: action.payload.products || [],
        count: action.payload.count || 0
      };
		case PRODUCT_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case PRODUCT_GET_ONE:
      return { ...state, loading: true, error: ''};
    case PRODUCT_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        product: action.payload.product
      };
    case PRODUCT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case PRODUCT_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case PRODUCT_ADD_ONE_SUCCESS:    
      NotificationManager.success("Added", "Success",3000, null, null, '');
      return { ...state, loading: false, 
        products: [action.payload.product, ...state.products]
      };
    case PRODUCT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case PRODUCT_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case PRODUCT_EDIT_ONE_SUCCESS:    
      NotificationManager.success("Edited", "Success", 3000, null, null, '');
      return { ...state, loading: false, error: '',
      product: action.payload.product
      };
    case PRODUCT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case PRODUCT_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case PRODUCT_DELETE_ONE_SUCCESS: 
    // console.log('PRODUCT_DELETE_ONE_SUCCESS', action.payload);
     
      const products = state.products 
      if(action.payload.index!=null && action.payload.index!=undefined)
      products.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        products
      };
    case PRODUCT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
