import {
  PROMOTION_GET_LIST,
	PROMOTION_GET_LIST_SUCCESS,
  PROMOTION_GET_LIST_ERROR,

  PROMOTION_GET_ONE,
	PROMOTION_GET_ONE_SUCCESS,
  PROMOTION_GET_ONE_ERROR,

  PROMOTION_ADD_ONE,
  PROMOTION_ADD_ONE_SUCCESS,
  PROMOTION_ADD_ONE_ERROR,

  PROMOTION_EDIT_ONE,
  PROMOTION_EDIT_ONE_SUCCESS,
  PROMOTION_EDIT_ONE_ERROR,

  PROMOTION_DELETE_ONE,
  PROMOTION_DELETE_ONE_SUCCESS,
  PROMOTION_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getPromotionsList = (payload) => ({
  type: PROMOTION_GET_LIST,
  payload
});
export const getPromotionsListSuccess = (payload) => ({
  type: PROMOTION_GET_LIST_SUCCESS,
  payload
});
export const getPromotionsListError = (payload) => ({
  type: PROMOTION_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOnePromotion = (payload) => ({
  type: PROMOTION_GET_ONE,
  payload
});
export const getOnePromotionSuccess = (payload) => ({
  type: PROMOTION_GET_ONE_SUCCESS,
  payload
});
export const getOnePromotionError = (payload) => ({
  type: PROMOTION_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addPromotion = (payload) => ({
  type: PROMOTION_ADD_ONE,
  payload
});
export const addPromotionSuccess = (payload) => ({
  type: PROMOTION_ADD_ONE_SUCCESS,
  payload
});
export const addPromotionError = (payload) => ({
  type: PROMOTION_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editPromotion = (payload) => ({
  type: PROMOTION_EDIT_ONE,
  payload
});
export const editPromotionSuccess = (payload) => ({
  type: PROMOTION_EDIT_ONE_SUCCESS,
  payload
});
export const editPromotionError = (payload) => ({
  type: PROMOTION_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOnePromotion = (payload) => ({
  type: PROMOTION_DELETE_ONE,
  payload
});
export const deleteOnePromotionSuccess = (payload) => ({
  type: PROMOTION_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePromotionError = (payload) => ({
  type: PROMOTION_DELETE_ONE_ERROR,
  payload
});