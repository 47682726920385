/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHANGE_TOKEN = "CHANGE_TOKEN";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP*/
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";

/* SURVEY LIST APP*/
export const SURVEY_LIST_GET_LIST = "SURVEY_LIST_GET_LIST";
export const SURVEY_LIST_GET_LIST_SUCCESS = "SURVEY_LIST_GET_LIST_SUCCESS";
export const SURVEY_LIST_GET_LIST_ERROR = "SURVEY_LIST_GET_LIST_ERROR";
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  "SURVEY_LIST_GET_LIST_WITH_FILTER";
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  "SURVEY_LIST_GET_LIST_WITH_ORDER";
export const SURVEY_LIST_GET_LIST_SEARCH = "SURVEY_LIST_GET_LIST_SEARCH";
export const SURVEY_LIST_ADD_ITEM = "SURVEY_LIST_ADD_ITEM";
export const SURVEY_LIST_ADD_ITEM_SUCCESS = "SURVEY_LIST_ADD_ITEM_SUCCESS";
export const SURVEY_LIST_ADD_ITEM_ERROR = "SURVEY_LIST_ADD_ITEM_ERROR";
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  "SURVEY_LIST_SELECTED_ITEMS_CHANGE";

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";


/********************************************************************************************************************************/


export const CTG_GET_LIST = "CTG_GET_LIST";
export const CTG_GET_LIST_SUCCESS = "CTG_GET_LIST_SUCCESS";
export const CTG_GET_LIST_ERROR = "CTG_GET_LIST_ERROR";

export const CTG_GET_ONE = "CTG_GET_ONE";
export const CTG_GET_ONE_SUCCESS = "CTG_GET_ONE_SUCCESS";
export const CTG_GET_ONE_ERROR = "CTG_GET_ONE_ERROR";

export const CTG_ADD_ONE = "CTG_ADD_ONE";
export const CTG_ADD_ONE_SUCCESS = "CTG_ADD_ONE_SUCCESS";
export const CTG_ADD_ONE_ERROR = "CTG_ADD_ONE_ERROR";

export const CTG_EDIT_ONE = "CTG_EDIT_ONE";
export const CTG_EDIT_ONE_SUCCESS = "CTG_EDIT_ONE_SUCCESS";
export const CTG_EDIT_ONE_ERROR = "CTG_EDIT_ONE_ERROR";

export const CTG_DELETE_ONE = "CTG_DELETE_ONE";
export const CTG_DELETE_ONE_SUCCESS = "CTG_DELETE_ONE_SUCCESS";
export const CTG_DELETE_ONE_ERROR = "CTG_DELETE_ONE_ERROR";

//************************************************************************************************************


export const PRODUCT_GET_LIST = "PRODUCT_GET_LIST";
export const PRODUCT_GET_LIST_SUCCESS = "PRODUCT_GET_LIST_SUCCESS";
export const PRODUCT_GET_LIST_ERROR = "PRODUCT_GET_LIST_ERROR";


export const PRODUCT_GET_ONE = "PRODUCT_GET_ONE";
export const PRODUCT_GET_ONE_SUCCESS = "PRODUCT_GET_ONE_SUCCESS";
export const PRODUCT_GET_ONE_ERROR = "PRODUCT_GET_ONE_ERROR";

export const PRODUCT_ADD_ONE = "PRODUCT_ADD_ONE";
export const PRODUCT_ADD_ONE_SUCCESS = "PRODUCT_ADD_ONE_SUCCESS";
export const PRODUCT_ADD_ONE_ERROR = "PRODUCT_ADD_ONE_ERROR";

export const PRODUCT_EDIT_ONE = "PRODUCT_EDIT_ONE";
export const PRODUCT_EDIT_ONE_SUCCESS = "PRODUCT_EDIT_ONE_SUCCESS";
export const PRODUCT_EDIT_ONE_ERROR = "PRODUCT_EDIT_ONE_ERROR";

export const PRODUCT_DELETE_ONE = "PRODUCT_DELETE_ONE";
export const PRODUCT_DELETE_ONE_SUCCESS = "PRODUCT_DELETE_ONE_SUCCESS";
export const PRODUCT_DELETE_ONE_ERROR = "PRODUCT_DELETE_ONE_ERROR";

//************************************************************************************************************

export const PACK_GET_LIST = "PACK_GET_LIST";
export const PACK_GET_LIST_SUCCESS = "PACK_GET_LIST_SUCCESS";
export const PACK_GET_LIST_ERROR = "PACK_GET_LIST_ERROR";


export const PACK_GET_ONE = "PACK_GET_ONE";
export const PACK_GET_ONE_SUCCESS = "PACK_GET_ONE_SUCCESS";
export const PACK_GET_ONE_ERROR = "PACK_GET_ONE_ERROR";

export const PACK_ADD_ONE = "PACK_ADD_ONE";
export const PACK_ADD_ONE_SUCCESS = "PACK_ADD_ONE_SUCCESS";
export const PACK_ADD_ONE_ERROR = "PACK_ADD_ONE_ERROR";

export const PACK_EDIT_ONE = "PACK_EDIT_ONE";
export const PACK_EDIT_ONE_SUCCESS = "PACK_EDIT_ONE_SUCCESS";
export const PACK_EDIT_ONE_ERROR = "PACK_EDIT_ONE_ERROR";

export const PACK_DELETE_ONE = "PACK_DELETE_ONE";
export const PACK_DELETE_ONE_SUCCESS = "PACK_DELETE_ONE_SUCCESS";
export const PACK_DELETE_ONE_ERROR = "PACK_DELETE_ONE_ERROR";

//************************************************************************************************************


export const PROMOTION_GET_LIST = "PROMOTION_GET_LIST";
export const PROMOTION_GET_LIST_SUCCESS = "PROMOTION_GET_LIST_SUCCESS";
export const PROMOTION_GET_LIST_ERROR = "PROMOTION_GET_LIST_ERROR";


export const PROMOTION_GET_ONE = "PROMOTION_GET_ONE";
export const PROMOTION_GET_ONE_SUCCESS = "PROMOTION_GET_ONE_SUCCESS";
export const PROMOTION_GET_ONE_ERROR = "PROMOTION_GET_ONE_ERROR";

export const PROMOTION_ADD_ONE = "PROMOTION_ADD_ONE";
export const PROMOTION_ADD_ONE_SUCCESS = "PROMOTION_ADD_ONE_SUCCESS";
export const PROMOTION_ADD_ONE_ERROR = "PROMOTION_ADD_ONE_ERROR";

export const PROMOTION_EDIT_ONE = "PROMOTION_EDIT_ONE";
export const PROMOTION_EDIT_ONE_SUCCESS = "PROMOTION_EDIT_ONE_SUCCESS";
export const PROMOTION_EDIT_ONE_ERROR = "PROMOTION_EDIT_ONE_ERROR";

export const PROMOTION_DELETE_ONE = "PROMOTION_DELETE_ONE";
export const PROMOTION_DELETE_ONE_SUCCESS = "PROMOTION_DELETE_ONE_SUCCESS";
export const PROMOTION_DELETE_ONE_ERROR = "PROMOTION_DELETE_ONE_ERROR";

//************************************************************************************************************

export const USER_GET_LIST = "USER_GET_LIST";
export const USER_GET_LIST_SUCCESS = "USER_GET_LIST_SUCCESS";
export const USER_GET_LIST_ERROR = "USER_GET_LIST_ERROR";

export const USER_GET_ONE = "USER_GET_ONE";
export const USER_GET_ONE_SUCCESS = "USER_GET_ONE_SUCCESS";
export const USER_GET_ONE_ERROR = "USER_GET_ONE_ERROR";

export const USER_ADD_ONE = "USER_ADD_ONE";
export const USER_ADD_ONE_SUCCESS = "USER_ADD_ONE_SUCCESS";
export const USER_ADD_ONE_ERROR = "USER_ADD_ONE_ERROR";

export const USER_EDIT_ONE = "USER_EDIT_ONE";
export const USER_EDIT_ONE_SUCCESS = "USER_EDIT_ONE_SUCCESS";
export const USER_EDIT_ONE_ERROR = "USER_EDIT_ONE_ERROR";

export const USER_DELETE_ONE = "USER_DELETE_ONE";
export const USER_DELETE_ONE_SUCCESS = "USER_DELETE_ONE_SUCCESS";
export const USER_DELETE_ONE_ERROR = "USER_DELETE_ONE_ERROR";

//************************************************************************************************************

export const TAX_GET_LIST = "TAX_GET_LIST";
export const TAX_GET_LIST_SUCCESS = "TAX_GET_LIST_SUCCESS";
export const TAX_GET_LIST_ERROR = "TAX_GET_LIST_ERROR";

export const TAX_GET_ONE = "TAX_GET_ONE";
export const TAX_GET_ONE_SUCCESS = "TAX_GET_ONE_SUCCESS";
export const TAX_GET_ONE_ERROR = "TAX_GET_ONE_ERROR";

export const TAX_ADD_ONE = "TAX_ADD_ONE";
export const TAX_ADD_ONE_SUCCESS = "TAX_ADD_ONE_SUCCESS";
export const TAX_ADD_ONE_ERROR = "TAX_ADD_ONE_ERROR";

export const TAX_EDIT_ONE = "TAX_EDIT_ONE";
export const TAX_EDIT_ONE_SUCCESS = "TAX_EDIT_ONE_SUCCESS";
export const TAX_EDIT_ONE_ERROR = "TAX_EDIT_ONE_ERROR";

export const TAX_DELETE_ONE = "TAX_DELETE_ONE";
export const TAX_DELETE_ONE_SUCCESS = "TAX_DELETE_ONE_SUCCESS";
export const TAX_DELETE_ONE_ERROR = "TAX_DELETE_ONE_ERROR";

//************************************************************************************************************

export const SESSION_GET_LIST = "SESSION_GET_LIST";
export const SESSION_GET_LIST_SUCCESS = "SESSION_GET_LIST_SUCCESS";
export const SESSION_GET_LIST_ERROR = "SESSION_GET_LIST_ERROR";


export const SESSION_GET_ONE = "SESSION_GET_ONE";
export const SESSION_GET_ONE_SUCCESS = "SESSION_GET_ONE_SUCCESS";
export const SESSION_GET_ONE_ERROR = "SESSION_GET_ONE_ERROR";

export const SESSION_ADD_ONE = "SESSION_ADD_ONE";
export const SESSION_ADD_ONE_SUCCESS = "SESSION_ADD_ONE_SUCCESS";
export const SESSION_ADD_ONE_ERROR = "SESSION_ADD_ONE_ERROR";

export const SESSION_EDIT_ONE = "SESSION_EDIT_ONE";
export const SESSION_EDIT_ONE_SUCCESS = "SESSION_EDIT_ONE_SUCCESS";
export const SESSION_EDIT_ONE_ERROR = "SESSION_EDIT_ONE_ERROR";

export const SESSION_DELETE_ONE = "SESSION_DELETE_ONE";
export const SESSION_DELETE_ONE_SUCCESS = "SESSION_DELETE_ONE_SUCCESS";
export const SESSION_DELETE_ONE_ERROR = "SESSION_DELETE_ONE_ERROR";

/********************************************************************************************************************************/


export const ORDER_GET_LIST = "ORDER_GET_LIST";
export const ORDER_GET_LIST_SUCCESS = "ORDER_GET_LIST_SUCCESS";
export const ORDER_GET_LIST_ERROR = "ORDER_GET_LIST_ERROR";

export const ORDER_COUNT = "ORDER_COUNT";
export const ORDER_COUNT_SUCCESS = "ORDER_COUNT_SUCCESS";
export const ORDER_COUNT_ERROR = "ORDER_COUNT_ERROR";

export const ORDER_GET_ONE = "ORDER_GET_ONE";
export const ORDER_GET_ONE_SUCCESS = "ORDER_GET_ONE_SUCCESS";
export const ORDER_GET_ONE_ERROR = "ORDER_GET_ONE_ERROR";

export const ORDER_ADD_ONE = "ORDER_ADD_ONE";
export const ORDER_ADD_ONE_SUCCESS = "ORDER_ADD_ONE_SUCCESS";
export const ORDER_ADD_ONE_ERROR = "ORDER_ADD_ONE_ERROR";

export const ORDER_EDIT_ONE = "ORDER_EDIT_ONE";
export const ORDER_EDIT_ONE_SUCCESS = "ORDER_EDIT_ONE_SUCCESS";
export const ORDER_EDIT_ONE_ERROR = "ORDER_EDIT_ONE_ERROR";

export const ORDER_DELETE_ONE = "ORDER_DELETE_ONE";
export const ORDER_DELETE_ONE_SUCCESS = "ORDER_DELETE_ONE_SUCCESS";
export const ORDER_DELETE_ONE_ERROR = "ORDER_DELETE_ONE_ERROR";

export const ORDER_GET_ARTICLES = "ORDER_GET_ARTICLES";
export const ORDER_GET_ARTICLES_SUCCESS = "ORDER_GET_ARTICLES_SUCCESS";
export const ORDER_GET_ARTICLES_ERROR = "ORDER_GET_ARTICLES_ERROR";

//************************************************************************************************************

export const TRAINING_GET_LIST = "TRAINING_GET_LIST";
export const TRAINING_GET_LIST_SUCCESS = "TRAINING_GET_LIST_SUCCESS";
export const TRAINING_GET_LIST_ERROR = "TRAINING_GET_LIST_ERROR";

export const TRAINING_GET_COUNT = "TRAINING_GET_COUNT";
export const TRAINING_GET_COUNT_SUCCESS = "TRAINING_GET_COUNT_SUCCESS";
export const TRAINING_GET_COUNT_ERROR = "TRAINING_GET_COUNT_ERROR";

export const TRAINING_GET_ONE = "TRAINING_GET_ONE";
export const TRAINING_GET_ONE_SUCCESS = "TRAINING_GET_ONE_SUCCESS";
export const TRAINING_GET_ONE_ERROR = "TRAINING_GET_ONE_ERROR";

export const TRAINING_ADD_ONE = "TRAINING_ADD_ONE";
export const TRAINING_ADD_ONE_SUCCESS = "TRAINING_ADD_ONE_SUCCESS";
export const TRAINING_ADD_ONE_ERROR = "TRAINING_ADD_ONE_ERROR";

export const TRAINING_EDIT_ONE = "TRAINING_EDIT_ONE";
export const TRAINING_EDIT_ONE_SUCCESS = "TRAINING_EDIT_ONE_SUCCESS";
export const TRAINING_EDIT_ONE_ERROR = "TRAINING_EDIT_ONE_ERROR";

export const TRAINING_DELETE_ONE = "TRAINING_DELETE_ONE";
export const TRAINING_DELETE_ONE_SUCCESS = "TRAINING_DELETE_ONE_SUCCESS";
export const TRAINING_DELETE_ONE_ERROR = "TRAINING_DELETE_ONE_ERROR";

//************************************************************************************************************

export const CONTACT_GET_LIST = "CONTACT_GET_LIST";
export const CONTACT_GET_LIST_SUCCESS = "CONTACT_GET_LIST_SUCCESS";
export const CONTACT_GET_LIST_ERROR = "CONTACT_GET_LIST_ERROR";

export const CONTACT_COUNT = "CONTACT_COUNT";
export const CONTACT_COUNT_SUCCESS = "CONTACT_COUNT_SUCCESS";
export const CONTACT_COUNT_ERROR = "CONTACT_COUNT_ERROR";

export const CONTACT_DELETE_ONE = "CONTACT_DELETE_ONE";
export const CONTACT_DELETE_ONE_SUCCESS = "CONTACT_DELETE_ONE_SUCCESS";
export const CONTACT_DELETE_ONE_ERROR = "CONTACT_DELETE_ONE_ERROR";

//************************************************************************************************************

export * from "./contacts/actions";
export * from "./trainings/actions";
export * from "./orders/actions";
export * from "./sessions/actions";
export * from "./taxes/actions";
export * from "./users/actions";
export * from "./promotions/actions";
export * from "./products/actions";
export * from "./packs/actions";
export * from "./ctgs/actions";
export * from "./menu/actions";
export * from "./settings/actions";
// export * from "./auth/actions";
// export * from "./todo/actions";
// export * from "./chat/actions";
// export * from "./surveyList/actions";
// export * from "./surveyDetail/actions";
