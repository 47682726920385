/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

// const dotenv = require('dotenv').parse();
// console.log('dotenv', dotenv)
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "fr", name: "Français", direction: "ltr" },
];

export const searchPath = "/app/pages/search";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey="__theme_color"
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = true;

const server = process.env.REACT_APP_API;
// const server = "http://localhost:5000/api"

export const api = {
  root: server,
  ctgs: `${server}/technic`,
  tech_store: `${server}/storage/technic/download/`,
  products: `${server}/article`,
  packs: `${server}/pack`,
  prd_store: `${server}/storage/article/download/`,
  promotions: `${server}/promoCode`,
  taxes: `${server}/taxes`,
  users: `${server}/user`,
  user_store: `${server}/storage/user/download/`,
  sessions: `${server}/session`,
  session_store: `${server}/storage/session/download/`,
  trainings: `${server}/training`,
  training_store: `${server}/storage/training/download/`,
  course_store: `${server}/storage/course/download/`,
  orders: `${server}/orders`,
  contacts: `${server}/contact`,
  topic: `${server}/Topic`,
}

export const icons = {
  no: '/assets/img/no.png',
  no_user: '/assets/img/no-user.png'
}