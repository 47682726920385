import {
  CONTACT_GET_LIST,
	CONTACT_GET_LIST_SUCCESS,
  CONTACT_GET_LIST_ERROR,

  CONTACT_COUNT,
  CONTACT_COUNT_SUCCESS,
  CONTACT_COUNT_ERROR,

  // CONTACT_GET_ONE,
	// CONTACT_GET_ONE_SUCCESS,
  // CONTACT_GET_ONE_ERROR,

  // CONTACT_ADD_ONE,
  // CONTACT_ADD_ONE_SUCCESS,
  // CONTACT_ADD_ONE_ERROR,

  // CONTACT_EDIT_ONE,
  // CONTACT_EDIT_ONE_SUCCESS,
  // CONTACT_EDIT_ONE_ERROR,

  CONTACT_DELETE_ONE,
  CONTACT_DELETE_ONE_SUCCESS,
  CONTACT_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getContactsList = (payload) => ({
  type: CONTACT_GET_LIST,
  payload
});
export const getContactsListSuccess = (payload) => ({
  type: CONTACT_GET_LIST_SUCCESS,
  payload
});
export const getContactsListError = (payload) => ({
  type: CONTACT_GET_LIST_ERROR,
  payload
});


  // GET COUNT
  export const getContactsCount = (payload) => ({
    type: CONTACT_COUNT,
    payload
  });
  export const getContactsCountSuccess = (payload) => ({
    type: CONTACT_COUNT_SUCCESS,
    payload
  });
  export const getContactsCountError = (payload) => ({
    type: CONTACT_COUNT_ERROR,
    payload
  });



  // DELETE ONE
export const deleteOneContact = (payload) => ({
  type: CONTACT_DELETE_ONE,
  payload
});
export const deleteOneContactSuccess = (payload) => ({
  type: CONTACT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneContactError = (payload) => ({
  type: CONTACT_DELETE_ONE_ERROR,
  payload
});