import {
  USER_GET_LIST,
	USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,

  USER_GET_ONE,
	USER_GET_ONE_SUCCESS,
  USER_GET_ONE_ERROR,

  USER_ADD_ONE,
  USER_ADD_ONE_SUCCESS,
  USER_ADD_ONE_ERROR,

  USER_EDIT_ONE,
  USER_EDIT_ONE_SUCCESS,
  USER_EDIT_ONE_ERROR,

  USER_DELETE_ONE,
  USER_DELETE_ONE_SUCCESS,
  USER_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getUsersList = (payload) => ({
  type: USER_GET_LIST,
  payload
});
export const getUsersListSuccess = (payload) => ({
  type: USER_GET_LIST_SUCCESS,
  payload
});
export const getUsersListError = (payload) => ({
  type: USER_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneUser = (payload) => ({
  type: USER_GET_ONE,
  payload
});
export const getOneUserSuccess = (payload) => ({
  type: USER_GET_ONE_SUCCESS,
  payload
});
export const getOneUserError = (payload) => ({
  type: USER_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addUser = (payload) => ({
  type: USER_ADD_ONE,
  payload
});
export const addUserSuccess = (payload) => ({
  type: USER_ADD_ONE_SUCCESS,
  payload
});
export const addUserError = (payload) => ({
  type: USER_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editUser = (payload) => ({
  type: USER_EDIT_ONE,
  payload
});
export const editUserSuccess = (payload) => ({
  type: USER_EDIT_ONE_SUCCESS,
  payload
});
export const editUserError = (payload) => ({
  type: USER_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneUser = (payload) => ({
  type: USER_DELETE_ONE,
  payload
});
export const deleteOneUserSuccess = (payload) => ({
  type: USER_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneUserError = (payload) => ({
  type: USER_DELETE_ONE_ERROR,
  payload
});