import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
// import authUser from './auth/reducer';
// import todoApp from './todo/reducer';
// import chatApp from './chat/reducer';
// import surveyListApp from './surveyList/reducer';
// import surveyDetailApp from './surveyDetail/reducer';

import Ctgs from "./ctgs/reducer";
import Products from "./products/reducer";
import Packs from "./packs/reducer";
import Promotions from "./promotions/reducer";
import Users from "./users/reducer";
import Taxes from "./taxes/reducer";
import Sessions from "./sessions/reducer";
import Orders from "./orders/reducer";
import Trainings from "./trainings/reducer";
import Contacts from "./contacts/reducer";

const reducers = combineReducers({
  Promotions,
  Products,
  Packs,
  Ctgs,
  menu,
  settings,
  Users,
  Taxes,
  Sessions,
  Orders,
  Trainings,
  Contacts,
  // authUser,
  // todoApp,
  // chatApp,
  // surveyListApp,
  // surveyDetailApp
});

export default reducers;