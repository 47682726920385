import {
	CTG_GET_LIST,
	CTG_GET_LIST_SUCCESS,
  CTG_GET_LIST_ERROR,

  CTG_GET_ONE,
	CTG_GET_ONE_SUCCESS,
  CTG_GET_ONE_ERROR,

  CTG_ADD_ONE,
  CTG_ADD_ONE_SUCCESS,
  CTG_ADD_ONE_ERROR,

  CTG_EDIT_ONE,
  CTG_EDIT_ONE_SUCCESS,
  CTG_EDIT_ONE_ERROR,

  CTG_DELETE_ONE,
  CTG_DELETE_ONE_SUCCESS,
  CTG_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

const INIT_STATE = {
	ctgs: [],
  count: 0,
  ctg: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CTG_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case CTG_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        ctgs: action.payload.ctgs || [],
        count: action.payload.count || 0
      };
		case CTG_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case CTG_GET_ONE:
      return { ...state, loading: true, error: ''};
    case CTG_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        ctg: action.payload.ctg
      };
    case CTG_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case CTG_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case CTG_ADD_ONE_SUCCESS:    
      NotificationManager.success("Added", "Success",3000, null, null, '');
      return { ...state, loading: false, 
        ctgs: [action.payload.ctg, ...state.ctgs]
      };
    case CTG_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case CTG_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case CTG_EDIT_ONE_SUCCESS:  
      NotificationManager.success("Edited", "Success", 3000, null, null, '');
      return { ...state, loading: false, error: '',
      ctg: action.payload.ctg
      };
    case CTG_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case CTG_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case CTG_DELETE_ONE_SUCCESS: 
    // console.log('CTG_DELETE_ONE_SUCCESS', action.payload);
     
      const ctgs = state.ctgs 
      if(action.payload.index!=null && action.payload.index!=undefined)
      ctgs.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        ctgs
      };
    case CTG_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
