import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { CONTACT_GET_LIST, CONTACT_DELETE_ONE, CONTACT_COUNT
} from "../actions";

import {
  getContactsListSuccess,
  getContactsListError,

  // getOneContactSuccess,
  // getOneContactError,

  // addContactSuccess,
  // addContactError,

  // editContactSuccess,
  // editContactError,
  deleteOneContactSuccess,
  deleteOneContactError,
  getContactsCountSuccess,
  getContactsCountError,
} from "./actions";


  //GET LIST
const getContactsListRequest = async (payload) => {
  return await axios.get(api.contacts+payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getContactsList({payload}) {
  try {
    const ret = yield call(getContactsListRequest, payload);

    if (ret && ret.status==200)
    yield put(getContactsListSuccess({contacts: ret.data || []}));
    
    else yield put(getContactsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getContactsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET COUNT
const getContactsCountRequest = async (payload) => {
  return await axios.get(api.contacts+"/count")
  // , { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getContactsCount({payload}) {
  try {
    const ret = yield call(getContactsCountRequest, payload);

    if (ret && ret.status==200)
    yield put(getContactsCountSuccess({count: ret.data.count || 0}));
    
    else yield put(getContactsCountError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getContactsCountError({error, message: (error) ? error.message : ''}));
  }
}




  // DELETE ONE
const deleteOneContactRequest = async (payload) => {
  return await axios.delete(`${api.contacts}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneContact({payload}) {  
  try {
    const ret = yield call(deleteOneContactRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      if(history) history.push('/data/contacts')
      yield put(deleteOneContactSuccess({index}));
    }
    else yield put(deleteOneContactError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneContactError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(CONTACT_GET_LIST, getContactsList);
}

export function* watchGetCount() {
  yield takeEvery(CONTACT_COUNT, getContactsCount);
}

export function* watchDeleteOne() {
  yield takeEvery(CONTACT_DELETE_ONE, deleteOneContact);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetCount),
    fork(watchDeleteOne)
  ]);
}
