import {
  PACK_GET_LIST,
	PACK_GET_LIST_SUCCESS,
  PACK_GET_LIST_ERROR,

  PACK_GET_ONE,
	PACK_GET_ONE_SUCCESS,
  PACK_GET_ONE_ERROR,

  PACK_ADD_ONE,
  PACK_ADD_ONE_SUCCESS,
  PACK_ADD_ONE_ERROR,

  PACK_EDIT_ONE,
  PACK_EDIT_ONE_SUCCESS,
  PACK_EDIT_ONE_ERROR,

  PACK_DELETE_ONE,
  PACK_DELETE_ONE_SUCCESS,
  PACK_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getPacksList = (payload) => ({
  type: PACK_GET_LIST,
  payload
});
export const getPacksListSuccess = (payload) => ({
  type: PACK_GET_LIST_SUCCESS,
  payload
});
export const getPacksListError = (payload) => ({
  type: PACK_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOnePack = (payload) => ({
  type: PACK_GET_ONE,
  payload
});
export const getOnePackSuccess = (payload) => ({
  type: PACK_GET_ONE_SUCCESS,
  payload
});
export const getOnePackError = (payload) => ({
  type: PACK_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addPack = (payload) => ({
  type: PACK_ADD_ONE,
  payload
});
export const addPackSuccess = (payload) => ({
  type: PACK_ADD_ONE_SUCCESS,
  payload
});
export const addPackError = (payload) => ({
  type: PACK_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editPack = (payload) => ({
  type: PACK_EDIT_ONE,
  payload
});
export const editPackSuccess = (payload) => ({
  type: PACK_EDIT_ONE_SUCCESS,
  payload
});
export const editPackError = (payload) => ({
  type: PACK_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOnePack = (payload) => ({
  type: PACK_DELETE_ONE,
  payload
});
export const deleteOnePackSuccess = (payload) => ({
  type: PACK_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePackError = (payload) => ({
  type: PACK_DELETE_ONE_ERROR,
  payload
});