// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// import "bootstrap/dist/css/bootstrap.css";
// import "font-awesome/css/font-awesome.min.css";
// import "./assets/scss/now-ui-dashboard.css";
// import 'react-notifications/lib/notifications.css';
// import "./assets/scss/icons.css";
// import "./assets/scss/fonts.css";
// import "react-datepicker/dist/react-datepicker.css";
// import "./assets/scss/style.css";

// import "./assets/css/sass/themes/gogo.light.orange.scss";

// import "./assets/css/sass/custom.scss";

// ReactDOM.render(<App />, document.getElementById('root'));

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';


import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
// import "./assets/scss/now-ui-dashboard.css";
import 'react-notifications/lib/notifications.css';
import "./assets/scss/icons.css";
import "./assets/scss/fonts.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/scss/style.css";

import "./assets/css/sass/themes/gogo.light.orange.scss";

import "./assets/css/sass/custom.scss";


const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);


// const color = 'red'
// let render = () => {
//   import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
//      require('./AppRenderer');
//   });
// };
// render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
