import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PACK_GET_LIST, PACK_ADD_ONE, PACK_GET_ONE, PACK_EDIT_ONE, PACK_DELETE_ONE
} from "../actions";

import {
  getPacksListSuccess,
  getPacksListError,

  getOnePackSuccess,
  getOnePackError,

  addPackSuccess,
  addPackError,

  editPackSuccess,
  editPackError,
  deleteOnePackSuccess,
  deleteOnePackError,
} from "./actions";


  //GET LIST
const getPacksListRequest = async (payload) => {
  return await axios.post(api.packs+"/filter?skip="+(payload.skip || 0)+"&limit="+(payload.limit || 10), payload.filter || {}, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getPacksList({payload}) {
  try {
    const ret = yield call(getPacksListRequest, payload);

    if (ret && ret.status==200)
    yield put(getPacksListSuccess({packs: (ret.data && ret.data.packs) || [], count: ret.data.count || 0}));
    
    else yield put(getPacksListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getPacksListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOnePacksRequest = async (payload) => {
  return await axios.get(api.packs+`/${payload._id}?filter={"include":["Technics"]}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOnePack({payload}) {  
  try {
    const ret = yield call(getOnePacksRequest, payload);

    if (ret && ret.status==200)
    yield put(getOnePackSuccess({pack: ret.data}));
    
    else yield put(getOnePackError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOnePackError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addPackRequest = async (payload) => {
  return await axios.post(api.packs+"/new?access_token="+payload.token, payload.data,
  ).then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addPack({payload}) {  
  try {
    const ret = yield call(addPackRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/packs')
      yield put(addPackSuccess({pack: ret.data}));
    }
    else yield put(addPackError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPackError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOnePackRequest = async (payload) => {
  return await axios.patch(`${api.packs}/change`, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOnePack({payload}) {  
  try {
    const ret = yield call(editOnePackRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/packs')
      yield put(editPackSuccess({pack: ret.data}));
    }
    else yield put(editPackError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPackError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOnePackRequest = async (payload) => {
  return await axios.delete(`${api.packs}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOnePack({payload}) {  
  try {
    const ret = yield call(deleteOnePackRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      axios.delete(`${api.root}/packArticles/deleteByPack?packId=${payload._id}&access_token=${payload.token}`)

      if(history) history.push('/shop/packs')
      yield put(deleteOnePackSuccess({index}));
    }
    else yield put(deleteOnePackError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePackError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PACK_GET_LIST, getPacksList);
}

export function* watchGetOne() {
  yield takeEvery(PACK_GET_ONE, getOnePack);
}

export function* watchEditOne() {
  yield takeEvery(PACK_EDIT_ONE, editOnePack);
}

export function* watchAddOne() {
  yield takeEvery(PACK_ADD_ONE, addPack);
}

export function* watchDeleteOne() {
  yield takeEvery(PACK_DELETE_ONE, deleteOnePack);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
