import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PROMOTION_GET_LIST, PROMOTION_ADD_ONE, PROMOTION_GET_ONE, PROMOTION_EDIT_ONE, PROMOTION_DELETE_ONE
} from "../actions";

import {
  getPromotionsListSuccess,
  getPromotionsListError,

  getOnePromotionSuccess,
  getOnePromotionError,

  addPromotionSuccess,
  addPromotionError,

  editPromotionSuccess,
  editPromotionError,
  deleteOnePromotionSuccess,
  deleteOnePromotionError,
} from "./actions";


  //GET LIST
const getPromotionsListRequest = async (payload) => {
  return await axios.get(api.promotions, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getPromotionsList({payload}) {
  try {
    const ret = yield call(getPromotionsListRequest, payload);

    if (ret && ret.status==200)
    yield put(getPromotionsListSuccess({promotions: ret.data || [], count: ret.data.count || 0}));
    
    else yield put(getPromotionsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getPromotionsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOnePromotionsRequest = async (payload) => {
  return await axios.get(api.promotions+`/${payload._id}`, { headers: {Authorization: payload.token} })
  // return await axios.get(api.root+'/PromoCodeEntities?filter={"include":["Article", "User"],"where":{"promoCodeId":"'+payload._id+'"}}&access_token='+payload.token
    // // , { headers: {Authorization: payload.token} }
  // )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOnePromotion({payload}) {  
  try {
    const ret = yield call(getOnePromotionsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOnePromotionSuccess({promotion: ret.data}));
    
    else yield put(getOnePromotionError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOnePromotionError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addPromotionRequest = async (payload) => {
  return await axios.post(api.promotions+"/new?access_token="+payload.token, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addPromotion({payload}) {  
  try {
    const ret = yield call(addPromotionRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/promos/product-promotions')
      yield put(addPromotionSuccess({promotion: ret.data}));
    }
    else yield put(addPromotionError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPromotionError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOnePromotionRequest = async (payload) => {
  return await axios.patch(`${api.promotions}/${payload._id}?access_token=${payload.token}`, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOnePromotion({payload}) {  
  try {
    const ret = yield call(editOnePromotionRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/promos/product-promotions')
      yield put(editPromotionSuccess({promotion: ret.data}));
    }
    else yield put(editPromotionError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPromotionError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOnePromotionRequest = async (payload) => {
  return await axios.delete(`${api.promotions}/${payload._id}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOnePromotion({payload}) {  
  try {
    const ret = yield call(deleteOnePromotionRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      if(history) history.push('/promos/product-promotions')
      yield put(deleteOnePromotionSuccess({index}));
    }
    else yield put(deleteOnePromotionError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePromotionError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PROMOTION_GET_LIST, getPromotionsList);
}

export function* watchGetOne() {
  yield takeEvery(PROMOTION_GET_ONE, getOnePromotion);
}

export function* watchEditOne() {
  yield takeEvery(PROMOTION_EDIT_ONE, editOnePromotion);
}

export function* watchAddOne() {
  yield takeEvery(PROMOTION_ADD_ONE, addPromotion);
}

export function* watchDeleteOne() {
  yield takeEvery(PROMOTION_DELETE_ONE, deleteOnePromotion);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
