import {
    CHANGE_LOCALE,
    CHANGE_TOKEN
} from '../actions';


export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

export const changeToken = (token) => {
  // localStorage.setItem('adminToken', token);
  return ({type: CHANGE_TOKEN, payload: token })
}

