import {
  CTG_GET_LIST,
	CTG_GET_LIST_SUCCESS,
  CTG_GET_LIST_ERROR,

  CTG_GET_ONE,
	CTG_GET_ONE_SUCCESS,
  CTG_GET_ONE_ERROR,

  CTG_ADD_ONE,
  CTG_ADD_ONE_SUCCESS,
  CTG_ADD_ONE_ERROR,

  CTG_EDIT_ONE,
  CTG_EDIT_ONE_SUCCESS,
  CTG_EDIT_ONE_ERROR,

  CTG_DELETE_ONE,
  CTG_DELETE_ONE_SUCCESS,
  CTG_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getCtgsList = (payload) => ({
  type: CTG_GET_LIST,
  payload
});
export const getCtgsListSuccess = (payload) => ({
  type: CTG_GET_LIST_SUCCESS,
  payload
});
export const getCtgsListError = (payload) => ({
  type: CTG_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneCtg = (payload) => ({
  type: CTG_GET_ONE,
  payload
});
export const getOneCtgSuccess = (payload) => ({
  type: CTG_GET_ONE_SUCCESS,
  payload
});
export const getOneCtgError = (payload) => ({
  type: CTG_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addCtg = (payload) => ({
  type: CTG_ADD_ONE,
  payload
});
export const addCtgSuccess = (payload) => ({
  type: CTG_ADD_ONE_SUCCESS,
  payload
});
export const addCtgError = (payload) => ({
  type: CTG_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editCtg = (payload) => ({
  type: CTG_EDIT_ONE,
  payload
});
export const editCtgSuccess = (payload) => ({
  type: CTG_EDIT_ONE_SUCCESS,
  payload
});
export const editCtgError = (payload) => ({
  type: CTG_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneCtg = (payload) => ({
  type: CTG_DELETE_ONE,
  payload
});
export const deleteOneCtgSuccess = (payload) => ({
  type: CTG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneCtgError = (payload) => ({
  type: CTG_DELETE_ONE_ERROR,
  payload
});