import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { CTG_GET_LIST, CTG_ADD_ONE, CTG_GET_ONE, CTG_EDIT_ONE, CTG_DELETE_ONE
} from "../actions";

import {
  getCtgsListSuccess,
  getCtgsListError,

  getOneCtgSuccess,
  getOneCtgError,

  addCtgSuccess,
  addCtgError,

  editCtgSuccess,
  editCtgError,
  deleteOneCtgSuccess,
  deleteOneCtgError,
} from "./actions";


  //GET LIST
const getCtgsListRequest = async (payload) => {
  return await axios.get(api.ctgs+"?filter[order]=index%20ASC", { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getCtgsList({payload}) {
  try {
    const ret = yield call(getCtgsListRequest, payload);

    if (ret && ret.status==200)
    yield put(getCtgsListSuccess({ctgs: ret.data || [], count: ret.data.count || 0}));
    
    else yield put(getCtgsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getCtgsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneCtgsRequest = async (payload) => {
  return await axios.get(api.ctgs+`/${payload._id}?filter[include][Articles][Article]`, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneCtg({payload}) {  
  try {
    const ret = yield call(getOneCtgsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneCtgSuccess({ctg: ret.data}));
    
    else yield put(getOneCtgError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneCtgError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addCtgRequest = async (payload) => {
  return await axios.post(api.ctgs+"/new?access_token="+payload.token, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addCtg({payload}) {  
  try {
    const ret = yield call(addCtgRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/academy/categories')
      yield put(addCtgSuccess({ctg: ret.data}));
    }
    else yield put(addCtgError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addCtgError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneCtgRequest = async (payload) => {
  return await axios.patch(`${api.ctgs}/change?access_token=${payload.token}`, payload.data, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneCtg({payload}) {  
  try {
    const ret = yield call(editOneCtgRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/academy/categories')
      yield put(editCtgSuccess({ctg: ret.data}));
    }
    else yield put(editCtgError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editCtgError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneCtgRequest = async (payload) => {
  return await axios.delete(`${api.ctgs}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneCtg({payload}) {  
  try {
    const ret = yield call(deleteOneCtgRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      axios.delete(`${api.root}/articlesTechnics/deleteByTechnic?technicId=${payload._id}&access_token=${payload.token}`)

      if(history) history.push('/academy/categories')
      yield put(deleteOneCtgSuccess({index}));
    }
    else yield put(deleteOneCtgError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneCtgError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(CTG_GET_LIST, getCtgsList);
}

export function* watchGetOne() {
  yield takeEvery(CTG_GET_ONE, getOneCtg);
}

export function* watchEditOne() {
  yield takeEvery(CTG_EDIT_ONE, editOneCtg);
}

export function* watchAddOne() {
  yield takeEvery(CTG_ADD_ONE, addCtg);
}

export function* watchDeleteOne() {
  yield takeEvery(CTG_DELETE_ONE, deleteOneCtg);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
