
import {defaultLocale,localeOptions} from '../../constants/defaultValues'

import {
  CHANGE_LOCALE,
  CHANGE_TOKEN
} from '../actions';

const INIT_STATE = {
  locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x=>x.id===localStorage.getItem('currentLanguage')).length>0) ? localStorage.getItem('currentLanguage') : defaultLocale,
  token: localStorage.getItem('adminToken')
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
    return { ...state, locale:action.payload};
    
    case CHANGE_TOKEN:
		return { ...state, token:action.payload};

		default: return { ...state };
	}
}