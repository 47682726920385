import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { TRAINING_GET_LIST, TRAINING_ADD_ONE, TRAINING_GET_ONE, TRAINING_EDIT_ONE, TRAINING_DELETE_ONE, TRAINING_GET_COUNT
} from "../actions";

import {
  getTrainingsListSuccess,
  getTrainingsListError,

  getOneTrainingSuccess,
  getOneTrainingError,

  addTrainingSuccess,
  addTrainingError,

  editTrainingSuccess,
  editTrainingError,
  deleteOneTrainingSuccess,
  deleteOneTrainingError,

  getTrainingsCountSuccess,
  getTrainingsCountError,
} from "./actions";


  //GET LIST
const getTrainingsListRequest = async (payload) => {
  // ?limit=1&skip=1
  return await axios.get(api.trainings+(payload.filter || "") , { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTrainingsList({payload}) {
  try {
    const ret = yield call(getTrainingsListRequest, payload);

    if (ret && ret.status==200)
    yield put(getTrainingsListSuccess({trainings: (payload.nested ? ret.data.trainings : ret.data) || [], count: ret.data.count || 0}));
    
    else yield put(getTrainingsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTrainingsListError({error, message: (error) ? error.message : ''}));
  }
}


//GET COUNT ALL
const getTrainingsCountRequest = async (payload) => {
  return await axios.get(api.trainings+"/count")
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTrainingsCount({payload}) {
  try {
    const ret = yield call(getTrainingsCountRequest, payload);

    if (ret && ret.status==200)
    yield put(getTrainingsCountSuccess({count: ret.data.count || 0}));
    
    else yield put(getTrainingsCountError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTrainingsCountError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneTrainingsRequest = async (payload) => {
  return await axios.get(api.trainings+`/${payload._id}?filter={"include":["Cover", "Technic"]}`, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneTraining({payload}) {  
  try {
    const ret = yield call(getOneTrainingsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneTrainingSuccess({training: ret.data}));
    
    else yield put(getOneTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneTrainingError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addTrainingRequest = async (payload) => {
  return await axios.post(api.trainings+"/new"+"?access_token="+payload.token, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addTraining({payload}) {  
  try {
    const ret = yield call(addTrainingRequest, payload);
    
    if (ret && ret.status==204){
      const { history } = payload;

      if(history) history.push('/app/trainings')
      yield put(addTrainingSuccess({training: ret.data}));
    }
    else yield put(addTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTrainingError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneTrainingRequest = async (payload) => {
  return await axios.patch(`${api.trainings}/change?access_token=${payload.token}`, payload.data, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneTraining({payload}) {  
  try {
    const ret = yield call(editOneTrainingRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/trainings')
      yield put(editTrainingSuccess({training: ret.data}));
    }
    else yield put(editTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editTrainingError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneTrainingRequest = async (payload) => {
  return await axios.delete(`${api.trainings}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneTraining({payload}) {  
  try {
    const ret = yield call(deleteOneTrainingRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      if(history) history.push('/app/trainings')
      yield put(deleteOneTrainingSuccess({index}));
    }
    else yield put(deleteOneTrainingError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTrainingError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TRAINING_GET_LIST, getTrainingsList);
}
export function* watchGetCount() {
  yield takeEvery(TRAINING_GET_COUNT, getTrainingsCount);
}

export function* watchGetOne() {
  yield takeEvery(TRAINING_GET_ONE, getOneTraining);
}

export function* watchEditOne() {
  yield takeEvery(TRAINING_EDIT_ONE, editOneTraining);
}

export function* watchAddOne() {
  yield takeEvery(TRAINING_ADD_ONE, addTraining);
}

export function* watchDeleteOne() {
  yield takeEvery(TRAINING_DELETE_ONE, deleteOneTraining);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetCount),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
