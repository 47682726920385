import {
	TAX_GET_LIST,
	TAX_GET_LIST_SUCCESS,
  TAX_GET_LIST_ERROR,

  TAX_GET_ONE,
	TAX_GET_ONE_SUCCESS,
  TAX_GET_ONE_ERROR,

  TAX_ADD_ONE,
  TAX_ADD_ONE_SUCCESS,
  TAX_ADD_ONE_ERROR,

  TAX_EDIT_ONE,
  TAX_EDIT_ONE_SUCCESS,
  TAX_EDIT_ONE_ERROR,

  TAX_DELETE_ONE,
  TAX_DELETE_ONE_SUCCESS,
  TAX_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

const INIT_STATE = {
	taxes: [],
  count: 0,
  tax: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case TAX_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case TAX_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        taxes: action.payload.taxes || [],
        count: action.payload.count || 0
      };
		case TAX_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case TAX_GET_ONE:
      return { ...state, loading: true, error: ''};
    case TAX_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        tax: action.payload.tax
      };
    case TAX_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case TAX_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case TAX_ADD_ONE_SUCCESS:    
      NotificationManager.success("Added", "Success",3000, null, null, '');
      return { ...state, loading: false, 
        taxes: [action.payload.tax, ...state.taxes]
      };
    case TAX_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case TAX_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case TAX_EDIT_ONE_SUCCESS:    
      NotificationManager.success("Edited", "Success", 3000, null, null, '');
      return { ...state, loading: false, error: '',
      tax: action.payload.tax
      };
    case TAX_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case TAX_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case TAX_DELETE_ONE_SUCCESS: 
     
      const taxes = state.taxes 
      if(action.payload.index!=null && action.payload.index!=undefined)
      taxes.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        taxes
      };
    case TAX_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
