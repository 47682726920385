import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { USER_GET_LIST, USER_ADD_ONE, USER_GET_ONE, USER_EDIT_ONE, USER_DELETE_ONE
} from "../actions";

import {
  getUsersListSuccess,
  getUsersListError,

  getOneUserSuccess,
  getOneUserError,

  addUserSuccess,
  addUserError,

  editUserSuccess,
  editUserError,
  deleteOneUserSuccess,
  deleteOneUserError,
} from "./actions";


  //GET LIST
const getUsersListRequest = async (payload) => {
  return await axios.get(api.users+"/allMembers")
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getUsersList({payload}) {
  try {
    const ret = yield call(getUsersListRequest, payload);

    if (ret && ret.status==200)
    yield put(getUsersListSuccess({users: ret.data.Users || [], count: ret.data.count || 0}));
    
    else yield put(getUsersListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getUsersListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneUsersRequest = async (payload) => {
  return await axios.get(api.users+`/${payload._id}/Profile?access_token=${payload.token}`, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneUser({payload}) {  
  try {
    const ret = yield call(getOneUsersRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneUserSuccess({user: ret.data}));
    
    else yield put(getOneUserError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneUserError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addUserRequest = async (payload) => {
  return await axios.post(api.users+"/new?access_token="+payload.token, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addUser({payload}) {  
  try {
    const ret = yield call(addUserRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/categories')
      yield put(addUserSuccess({user: ret.data}));
    }
    else yield put(addUserError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addUserError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneUserRequest = async (payload) => {
  return await axios.patch(`${api.users}/change?access_token=${payload.token}`, payload.data, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneUser({payload}) {  
  try {
    const ret = yield call(editOneUserRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/categories')
      yield put(editUserSuccess({user: ret.data}));
    }
    else yield put(editUserError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editUserError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneUserRequest = async (payload) => {
  return await axios.delete(`${api.users}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneUser({payload}) {  
  try {
    const ret = yield call(deleteOneUserRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      if(history) history.push('/shop/categories')
      yield put(deleteOneUserSuccess({index}));
    }
    else yield put(deleteOneUserError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneUserError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(USER_GET_LIST, getUsersList);
}

export function* watchGetOne() {
  yield takeEvery(USER_GET_ONE, getOneUser);
}

export function* watchEditOne() {
  yield takeEvery(USER_EDIT_ONE, editOneUser);
}

export function* watchAddOne() {
  yield takeEvery(USER_ADD_ONE, addUser);
}

export function* watchDeleteOne() {
  yield takeEvery(USER_DELETE_ONE, deleteOneUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
