import {
	PROMOTION_GET_LIST,
	PROMOTION_GET_LIST_SUCCESS,
  PROMOTION_GET_LIST_ERROR,

  PROMOTION_GET_ONE,
	PROMOTION_GET_ONE_SUCCESS,
  PROMOTION_GET_ONE_ERROR,

  PROMOTION_ADD_ONE,
  PROMOTION_ADD_ONE_SUCCESS,
  PROMOTION_ADD_ONE_ERROR,

  PROMOTION_EDIT_ONE,
  PROMOTION_EDIT_ONE_SUCCESS,
  PROMOTION_EDIT_ONE_ERROR,

  PROMOTION_DELETE_ONE,
  PROMOTION_DELETE_ONE_SUCCESS,
  PROMOTION_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

const INIT_STATE = {
	promotions: [],
  count: 0,
  promotion: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PROMOTION_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case PROMOTION_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        promotions: action.payload.promotions || [],
        count: action.payload.count || 0
      };
		case PROMOTION_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case PROMOTION_GET_ONE:
      return { ...state, loading: true, error: ''};
    case PROMOTION_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        promotion: action.payload.promotion
      };
    case PROMOTION_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case PROMOTION_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case PROMOTION_ADD_ONE_SUCCESS:    
      NotificationManager.success("Added", "Success",3000, null, null, '');
      return { ...state, loading: false, 
        promotions: [action.payload.promotion, ...state.promotions]
      };
    case PROMOTION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case PROMOTION_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case PROMOTION_EDIT_ONE_SUCCESS:    
      NotificationManager.success("Edited", "Success", 3000, null, null, '');
      return { ...state, loading: false, error: '',
      promotion: action.payload.promotion
      };
    case PROMOTION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case PROMOTION_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case PROMOTION_DELETE_ONE_SUCCESS: 
      const promotions = state.promotions 
      if(action.payload.index!=null && action.payload.index!=undefined)
      promotions.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        promotions
      };
    case PROMOTION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
