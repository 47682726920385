import {
  ORDER_GET_LIST,
	ORDER_GET_LIST_SUCCESS,
  ORDER_GET_LIST_ERROR,

  ORDER_COUNT,
  ORDER_COUNT_SUCCESS,
  ORDER_COUNT_ERROR,

  ORDER_GET_ARTICLES,
  ORDER_GET_ARTICLES_SUCCESS,
  ORDER_GET_ARTICLES_ERROR,

  ORDER_GET_ONE,
	ORDER_GET_ONE_SUCCESS,
  ORDER_GET_ONE_ERROR,

  ORDER_ADD_ONE,
  ORDER_ADD_ONE_SUCCESS,
  ORDER_ADD_ONE_ERROR,

  ORDER_EDIT_ONE,
  ORDER_EDIT_ONE_SUCCESS,
  ORDER_EDIT_ONE_ERROR,

  ORDER_DELETE_ONE,
  ORDER_DELETE_ONE_SUCCESS,
  ORDER_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getOrdersList = (payload) => ({
  type: ORDER_GET_LIST,
  payload
});
export const getOrdersListSuccess = (payload) => ({
  type: ORDER_GET_LIST_SUCCESS,
  payload
});
export const getOrdersListError = (payload) => ({
  type: ORDER_GET_LIST_ERROR,
  payload
});


  // GET COUNT
  export const getOrdersCount = (payload) => ({
    type: ORDER_COUNT,
    payload
  });
  export const getOrdersCountSuccess = (payload) => ({
    type: ORDER_COUNT_SUCCESS,
    payload
  });
  export const getOrdersCountError = (payload) => ({
    type: ORDER_COUNT_ERROR,
    payload
  });


  // GET ORDER ARTICLES
  export const getOrderArticles = (payload) => ({
    type: ORDER_GET_ARTICLES,
    payload
  });
  export const getOrderArticlesSuccess = (payload) => ({
    type: ORDER_GET_ARTICLES_SUCCESS,
    payload
  });
  export const getOrderArticlesError = (payload) => ({
    type: ORDER_GET_ARTICLES_ERROR,
    payload
  });







  // GET LIST
export const getOneOrder = (payload) => ({
  type: ORDER_GET_ONE,
  payload
});
export const getOneOrderSuccess = (payload) => ({
  type: ORDER_GET_ONE_SUCCESS,
  payload
});
export const getOneOrderError = (payload) => ({
  type: ORDER_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addOrder = (payload) => ({
  type: ORDER_ADD_ONE,
  payload
});
export const addOrderSuccess = (payload) => ({
  type: ORDER_ADD_ONE_SUCCESS,
  payload
});
export const addOrderError = (payload) => ({
  type: ORDER_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editOrder = (payload) => ({
  type: ORDER_EDIT_ONE,
  payload
});
export const editOrderSuccess = (payload) => ({
  type: ORDER_EDIT_ONE_SUCCESS,
  payload
});
export const editOrderError = (payload) => ({
  type: ORDER_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneOrder = (payload) => ({
  type: ORDER_DELETE_ONE,
  payload
});
export const deleteOneOrderSuccess = (payload) => ({
  type: ORDER_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneOrderError = (payload) => ({
  type: ORDER_DELETE_ONE_ERROR,
  payload
});