import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PRODUCT_GET_LIST, PRODUCT_ADD_ONE, PRODUCT_GET_ONE, PRODUCT_EDIT_ONE, PRODUCT_DELETE_ONE
} from "../actions";

import {
  getProductsListSuccess,
  getProductsListError,

  getOneProductSuccess,
  getOneProductError,

  addProductSuccess,
  addProductError,

  editProductSuccess,
  editProductError,
  deleteOneProductSuccess,
  deleteOneProductError,
} from "./actions";


  //GET LIST
const getProductsListRequest = async (payload) => {
  return await axios.post(api.products+"/filter?skip="+(payload.skip || 0)+"&limit="+(payload.limit || 10), payload.filter || {}, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getProductsList({payload}) {
  try {
    const ret = yield call(getProductsListRequest, payload);

    if (ret && ret.status==200)
    yield put(getProductsListSuccess({products: (ret.data && ret.data.articles) || [], count: ret.data.count || 0}));
    
    else yield put(getProductsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getProductsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneProductsRequest = async (payload) => {
  return await axios.get(api.products+`/${payload._id}?filter={"include":["Technics"]}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneProduct({payload}) {  
  try {
    const ret = yield call(getOneProductsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneProductSuccess({product: ret.data}));
    
    else yield put(getOneProductError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneProductError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addProductRequest = async (payload) => {
  return await axios.post(api.products+"/new?access_token="+payload.token, payload.data,
  ).then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addProduct({payload}) {  
  try {
    const ret = yield call(addProductRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/products')
      yield put(addProductSuccess({product: ret.data}));
    }
    else yield put(addProductError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addProductError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneProductRequest = async (payload) => {
  return await axios.patch(`${api.products}/change`, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneProduct({payload}) {  
  try {
    const ret = yield call(editOneProductRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/products')
      yield put(editProductSuccess({product: ret.data}));
    }
    else yield put(editProductError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editProductError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneProductRequest = async (payload) => {
  return await axios.delete(`${api.products}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneProduct({payload}) {  
  try {
    const ret = yield call(deleteOneProductRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      axios.delete(`${api.root}/articlesTechnics/deleteByArticle?articleId=${payload._id}&access_token=${payload.token}`)

      if(history) history.push('/shop/products')
      yield put(deleteOneProductSuccess({index}));
    }
    else yield put(deleteOneProductError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneProductError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PRODUCT_GET_LIST, getProductsList);
}

export function* watchGetOne() {
  yield takeEvery(PRODUCT_GET_ONE, getOneProduct);
}

export function* watchEditOne() {
  yield takeEvery(PRODUCT_EDIT_ONE, editOneProduct);
}

export function* watchAddOne() {
  yield takeEvery(PRODUCT_ADD_ONE, addProduct);
}

export function* watchDeleteOne() {
  yield takeEvery(PRODUCT_DELETE_ONE, deleteOneProduct);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
