import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { ORDER_GET_LIST, ORDER_ADD_ONE, ORDER_GET_ONE, ORDER_EDIT_ONE, ORDER_DELETE_ONE, ORDER_GET_ARTICLES,
  ORDER_COUNT
} from "../actions";

import {
  getOrdersListSuccess,
  getOrdersListError,

  getOneOrderSuccess,
  getOneOrderError,

  addOrderSuccess,
  addOrderError,

  editOrderSuccess,
  editOrderError,
  deleteOneOrderSuccess,
  deleteOneOrderError,
  getOrderArticlesSuccess,
  getOrderArticlesError,

  getOrdersCountSuccess,
  getOrdersCountError,
} from "./actions";


  //GET LIST
const getOrdersListRequest = async (payload) => {
  return await axios.get(api.orders+payload.filter)
  //, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOrdersList({payload}) {
  try {
    const ret = yield call(getOrdersListRequest, payload);

    if (ret && ret.status==200)
    yield put(getOrdersListSuccess({orders: ret.data || [], count: ret.data.count || 0}));
    
    else yield put(getOrdersListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOrdersListError({error, message: (error) ? error.message : ''}));
  }
}

//GET COUNT
const getOrdersCountRequest = async (payload) => {
  return await axios.get(api.orders+"/count"+(payload.filter ? payload.filter : ""))
  // , { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOrdersCount({payload}) {
  try {
    const ret = yield call(getOrdersCountRequest, payload);

    if (ret && ret.status==200)
    yield put(getOrdersCountSuccess({count: ret.data.count || 0}));
    
    else yield put(getOrdersCountError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOrdersCountError({error, message: (error) ? error.message : ''}));
  }
}

  //GET ORDER ARTICLES
  const getOrderArticlesRequest = async (payload) => {
    // return await axios.get(api.root+"/OrdersArticles?access_token="+payload.token+"&"+payload.filter)
    return await axios.get(api.orders+"/articles?access_token="+payload.token+"&orderId="+payload.orderId)
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getOrderArticles({payload}) {
    try {
      const ret = yield call(getOrderArticlesRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOrderArticlesSuccess({articles: ret.data.articles || [], count: ret.data.count || 0}));
      
      else yield put(getOrderArticlesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOrderArticlesError({error, message: (error) ? error.message : ''}));
    }
  }


//GET ONE
const getOneOrdersRequest = async (payload) => {
  return await axios.get(api.orders+`/${payload._id}`, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneOrder({payload}) {  
  try {
    const ret = yield call(getOneOrdersRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneOrderSuccess({order: ret.data}));
    
    else yield put(getOneOrderError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneOrderError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addOrderRequest = async (payload) => {
  return await axios.post(api.orders+"/requestOrder?access_token="+payload.token, payload.data)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addOrder({payload}) {  
  try {
    const ret = yield call(addOrderRequest, payload);
    
    if (ret && (ret.status==204 || ret.status==200)){
      const { history } = payload;
      if(history) history.push('/shop/orders')
      yield put(addOrderSuccess({order: ret.data}));
    }
    else yield put(addOrderError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addOrderError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneOrderRequest = async (payload) => {
  return await axios.patch(`${api.orders}/change?access_token=${payload.token}`, payload.data, 
    // { headers: {Authorization: payload.token} }
  )
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneOrder({payload}) {  
  try {
    const ret = yield call(editOneOrderRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/shop/orders')
      yield put(editOrderSuccess({order: ret.data}));
    }
    else yield put(editOrderError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOrderError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneOrderRequest = async (payload) => {
  return await axios.delete(`${api.orders}/${payload._id}?access_token=${payload.token}`)
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneOrder({payload}) {  
  try {
    const ret = yield call(deleteOneOrderRequest, payload);
    
    if (ret && ret.status==200){
      const { history, index } = payload;

      if(history) history.push('/shop/orders')
      yield put(deleteOneOrderSuccess({index}));
    }
    else yield put(deleteOneOrderError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneOrderError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(ORDER_GET_LIST, getOrdersList);
}

export function* watchGetCount() {
  yield takeEvery(ORDER_COUNT, getOrdersCount);
}

export function* watchGetListArticles() {
  yield takeEvery(ORDER_GET_ARTICLES, getOrderArticles);
}

export function* watchGetOne() {
  yield takeEvery(ORDER_GET_ONE, getOneOrder);
}

export function* watchEditOne() {
  yield takeEvery(ORDER_EDIT_ONE, editOneOrder);
}

export function* watchAddOne() {
  yield takeEvery(ORDER_ADD_ONE, addOrder);
}

export function* watchDeleteOne() {
  yield takeEvery(ORDER_DELETE_ONE, deleteOneOrder);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetCount),
    fork(watchGetListArticles),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
