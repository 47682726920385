import {
  PRODUCT_GET_LIST,
	PRODUCT_GET_LIST_SUCCESS,
  PRODUCT_GET_LIST_ERROR,

  PRODUCT_GET_ONE,
	PRODUCT_GET_ONE_SUCCESS,
  PRODUCT_GET_ONE_ERROR,

  PRODUCT_ADD_ONE,
  PRODUCT_ADD_ONE_SUCCESS,
  PRODUCT_ADD_ONE_ERROR,

  PRODUCT_EDIT_ONE,
  PRODUCT_EDIT_ONE_SUCCESS,
  PRODUCT_EDIT_ONE_ERROR,

  PRODUCT_DELETE_ONE,
  PRODUCT_DELETE_ONE_SUCCESS,
  PRODUCT_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getProductsList = (payload) => ({
  type: PRODUCT_GET_LIST,
  payload
});
export const getProductsListSuccess = (payload) => ({
  type: PRODUCT_GET_LIST_SUCCESS,
  payload
});
export const getProductsListError = (payload) => ({
  type: PRODUCT_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneProduct = (payload) => ({
  type: PRODUCT_GET_ONE,
  payload
});
export const getOneProductSuccess = (payload) => ({
  type: PRODUCT_GET_ONE_SUCCESS,
  payload
});
export const getOneProductError = (payload) => ({
  type: PRODUCT_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addProduct = (payload) => ({
  type: PRODUCT_ADD_ONE,
  payload
});
export const addProductSuccess = (payload) => ({
  type: PRODUCT_ADD_ONE_SUCCESS,
  payload
});
export const addProductError = (payload) => ({
  type: PRODUCT_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editProduct = (payload) => ({
  type: PRODUCT_EDIT_ONE,
  payload
});
export const editProductSuccess = (payload) => ({
  type: PRODUCT_EDIT_ONE_SUCCESS,
  payload
});
export const editProductError = (payload) => ({
  type: PRODUCT_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneProduct = (payload) => ({
  type: PRODUCT_DELETE_ONE,
  payload
});
export const deleteOneProductSuccess = (payload) => ({
  type: PRODUCT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneProductError = (payload) => ({
  type: PRODUCT_DELETE_ONE_ERROR,
  payload
});