import {
	TRAINING_GET_LIST,
	TRAINING_GET_LIST_SUCCESS,
  TRAINING_GET_LIST_ERROR,

  TRAINING_GET_COUNT,
	TRAINING_GET_COUNT_SUCCESS,
  TRAINING_GET_COUNT_ERROR,

  TRAINING_GET_ONE,
	TRAINING_GET_ONE_SUCCESS,
  TRAINING_GET_ONE_ERROR,

  TRAINING_ADD_ONE,
  TRAINING_ADD_ONE_SUCCESS,
  TRAINING_ADD_ONE_ERROR,

  TRAINING_EDIT_ONE,
  TRAINING_EDIT_ONE_SUCCESS,
  TRAINING_EDIT_ONE_ERROR,

  TRAINING_DELETE_ONE,
  TRAINING_DELETE_ONE_SUCCESS,
  TRAINING_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

const INIT_STATE = {
	trainings: [],
  count: 0,
  training: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case TRAINING_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case TRAINING_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        trainings: action.payload.trainings || [],
        count: action.payload.count || 0
      };
		case TRAINING_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET COUNT ALL
    case TRAINING_GET_COUNT:
      return { ...state, loading: true, error: '', count: 0 };
    case TRAINING_GET_COUNT_SUCCESS:
      return { ...state, loading: false, count: action.payload.count || 0 };
    case TRAINING_GET_COUNT_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case TRAINING_GET_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        training: action.payload.training
      };
    case TRAINING_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case TRAINING_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_ADD_ONE_SUCCESS:    
      NotificationManager.success("Added", "Success",3000, null, null, '');
      return { ...state, loading: false, 
        trainings: [action.payload.training, ...state.trainings]
      };
    case TRAINING_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case TRAINING_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_EDIT_ONE_SUCCESS:    
      NotificationManager.success("Edited", "Success", 3000, null, null, '');
      return { ...state, loading: false, error: '',
      training: action.payload.training
      };
    case TRAINING_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case TRAINING_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case TRAINING_DELETE_ONE_SUCCESS: 
    // console.log('TRAINING_DELETE_ONE_SUCCESS', action.payload);
     
      const trainings = state.trainings 
      if(action.payload.index!=null && action.payload.index!=undefined)
      trainings.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        trainings
      };
    case TRAINING_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
